<template>
    <div class="part-wrapper" @click="showEdit">
        <div class="content" style="white-space: pre-line;" v-html="content"  />
        <button 
            v-if="commented && user" 
            :class="['indicator', unresolved_comment && 'unresolved']" 
            @click.stop.prevent="$emit('showCommentModal')"
        >|
            <q-tooltip v-if="!unresolved_comment" v-html="resource[lang].commented" />
            <q-tooltip v-if="unresolved_comment" v-html="resource[lang].unresolved" />
        </button>
        <q-icon 
            v-if="user"
            name="fas fa-ellipsis-v" 
            size="1rem" 
            color="dark_grey"
            class="comment-button"
            tabindex="0"
            @click.stop="$emit('showCommentModal')"
        >
            <q-tooltip v-html="resource[lang].comment" />
        </q-icon>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import resource from '@/assets/resources/matrix-sequence-part-resource'

export default {
    props: {
        content: String,

        commented: Boolean,
        readonly: Boolean,
        unresolved_comment: Boolean,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    methods: {
        showEdit() {
            if (!this.readonly) {
                this.$emit('showEdit')
            }
        }
    },
    computed: {
        ...mapGetters(['user'],)
    },
    emits: ['showCommentModal', 'showEdit']
}
</script>

<style lang="scss" scoped>

.comment-button {
    display: none;

    position: absolute;
    top: -1rem;
    left: -0.75rem;
    padding-block-start: 1.5rem;
    padding-block-end: 1.5rem;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
    z-index: 1;

    cursor: pointer;
}

.comment-button:hover {
    color: var(--blak) !important;
}

.indicator {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    cursor: pointer;

    background-color: var(--primary);
    color: var(--primary);
    border: none;

    width: 5px;
    height: calc(100% - 4px);

    margin: 2px;
}

.indicator:hover, 
.indicator:focus-visible {
    background-color: var(--accent);
    color: var(--accent);
}

.unresolved {
    background-color: var(--light_blue);
    color: var(--light_blue);
}

.part-wrapper {
    position: relative;

    &:hover .comment-button {
        display: block;
    }
}

</style>