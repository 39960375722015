<template>
    <div class="wrapper">
        <form class="basic-form" id="matrix-form" @submit.prevent="handleSubmit">
            <h2 class="basic-form-heading-1" v-html="resource[lang].general_information.heading" />
            <div class="general">
                <InputText :commentable="matrix_id !== null" required :readonly="!update || disable"
                    v-model="name" 
                    input_field_name="name"
                    :label="resource[lang].general_information.field_name"
                    :unresolved_comment="unresolved_input_field_names.includes('name')"
                    :commented="commented_input_field_names.includes('name')"
                    @showCommentModal="showCommentModal"
                />
                <InputText :commentable="matrix_id !== null" :readonly="!update || disable"
                    v-model="topic" 
                    input_field_name="topic"
                    :label="resource[lang].general_information.field_topic"
                    :unresolved_comment="unresolved_input_field_names.includes('topic')"
                    :commented="commented_input_field_names.includes('topic')"
                    @showCommentModal="showCommentModal"
                />
                <InputText :commentable="matrix_id !== null" :readonly="!update || disable"
                    v-model="course_nr" 
                    input_field_name="course_nr"
                    :label="resource[lang].general_information.field_course_nr"
                    :unresolved_comment="unresolved_input_field_names.includes('course_nr')"
                    :commented="commented_input_field_names.includes('course_nr')"
                    :help_text="resource[lang].help_texts.field_course_nr"
                    @showCommentModal="showCommentModal"
                    @showHelpModal="showHelpModal"
                />
                <InputText :commentable="matrix_id !== null" :readonly="!update || disable"
                    v-model="teachers" 
                    input_field_name="teachers"
                    :label="resource[lang].general_information.field_teachers"
                    :unresolved_comment="unresolved_input_field_names.includes('teachers')"
                    :commented="commented_input_field_names.includes('teachers')"
                    @showCommentModal="showCommentModal"
                />
                <InputText :commentable="matrix_id !== null" :readonly="!update || disable"
                    v-model="stage" 
                    input_field_name="stage"
                    :label="resource[lang].general_information.field_stage"
                    :unresolved_comment="unresolved_input_field_names.includes('stage')"
                    :commented="commented_input_field_names.includes('stage')"
                    :help_text="resource[lang].help_texts.field_stage"
                    @showCommentModal="showCommentModal"
                    @showHelpModal="showHelpModal"
                />
                <InputRadioButtons :readonly="!update || disable"
                    :options="temporal_framework_options" 
                    :labels="temporal_framework_options" 
                    :label="resource[lang].general_information.field_temporal_framework"
                    v-model="preliminary_temporal_framework" 
                />
                <transition name="fade">
                    <InputText :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-show="preliminary_temporal_framework === 'Block (from - to)' || preliminary_temporal_framework === 'Other'"
                        v-model="text_temporal_framework" 
                        input_field_name="temporal_framework"
                        :label="resource[lang].general_information.field_temporal_framework"
                        :unresolved_comment="unresolved_input_field_names.includes('temporal_framework')"
                        :commented="commented_input_field_names.includes('temporal_framework')"
                        @showCommentModal="showCommentModal"
                    />
                </transition>

                <div class="substantive-concepts">
                    <h3 class="basic-form-heading-2" v-html="resource[lang].general_information.emphasis_on_substantive_concepts.heading" />
                    <p class="basic-form-text" v-html="resource[lang].general_information.emphasis_on_substantive_concepts.text" />
                    <ul class="basic-form-text example-list">
                        <p v-html="resource[lang].general_information.emphasis_on_substantive_concepts.list.text" />
                        <li 
                            v-for="(item, index) in resource[lang].general_information.emphasis_on_substantive_concepts.list.list_items"
                            :key="index"
                            v-html="item"
                        />
                    </ul>
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="substantive_concepts" 
                        input_field_name="substantive_concepts"
                        :label="resource[lang].general_information.emphasis_on_substantive_concepts.field_substantive_concepts"
                        :unresolved_comment="unresolved_input_field_names.includes('substantive_concepts')"
                        :commented="commented_input_field_names.includes('substantive_concepts')"
                        @showCommentModal="showCommentModal"
                    />
                </div>

                <div class="historical-thinking-concepts">
                    <h3 class="basic-form-heading-2" v-html="resource[lang].general_information.emphasis_on_historical_thinking_concepts.heading" />
                    <p class="basic-form-text" v-html="resource[lang].general_information.emphasis_on_historical_thinking_concepts.text" />
                    <ul class="basic-form-text example-list">
                        <p v-html="resource[lang].general_information.emphasis_on_historical_thinking_concepts.list.text" />
                        <li 
                            v-for="(item, index) in resource[lang].general_information.emphasis_on_historical_thinking_concepts.list.list_items"
                            :key="index"
                            v-html="item"
                        />
                    </ul>
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="historical_thinking_concepts" 
                        input_field_name="historical_thinking_concepts"
                        :label="resource[lang].general_information.emphasis_on_historical_thinking_concepts.field_historical_thinking_concepts"
                        :unresolved_comment="unresolved_input_field_names.includes('historical_thinking_concepts')"
                        :commented="commented_input_field_names.includes('historical_thinking_concepts')"
                        @showCommentModal="showCommentModal"
                    />
                </div>
            </div>

            <div class="target-group-and-teachers-belief">
                <h2 class="basic-form-heading-1" v-html="resource[lang].information_about_target_group_and_teachers_beliefs.heading" />
                <div class="target-group">
                    <h3 
                        class="basic-form-heading-2" 
                        v-html="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.heading" 
                    />
                    <p class="basic-form-text" v-html="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.text" />

                    <div class="grid grid-3">
                        <InputText :commentable="matrix_id !== null" type="number" :readonly="!update || disable"
                            v-model="male_participants" 
                            input_field_name="male_participants"
                            :label="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.field_male_participants"
                            :unresolved_comment="unresolved_input_field_names.includes('male_participants')"
                            :commented="commented_input_field_names.includes('male_participants')"
                            @showCommentModal="showCommentModal"
                        />
                        <InputText :commentable="matrix_id !== null" type="number" :readonly="!update || disable"
                            v-model="female_participants" 
                            input_field_name="female_participants"
                            :label="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.field_female_participants"
                            :unresolved_comment="unresolved_input_field_names.includes('female_participants')"
                            :commented="commented_input_field_names.includes('female_participants')"
                            @showCommentModal="showCommentModal"
                        />
                        <InputText :commentable="matrix_id !== null" type="number" :readonly="!update || disable"
                            v-model="divers_participants" 
                            input_field_name="divers_participants"
                            :label="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.field_divers_participants"
                            :unresolved_comment="unresolved_input_field_names.includes('divers_participants')"
                            :commented="commented_input_field_names.includes('divers_participants')"
                            @showCommentModal="showCommentModal"
                        />
                    </div>
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="target_group_composition" 
                        input_field_name="target_group_composition"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.field_target_group_composition"
                        :unresolved_comment="unresolved_input_field_names.includes('target_group_composition')"
                        :commented="commented_input_field_names.includes('target_group_composition')"
                        :help_text="resource[lang].help_texts.field_target_group_composition"
                        @showCommentModal="showCommentModal"
                        @showHelpModal="showHelpModal"
                    />
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="subject_knowledge" 
                        input_field_name="subject_knowledge"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.field_subject_knowledge"
                        :unresolved_comment="unresolved_input_field_names.includes('subject_knowledge')"
                        :commented="commented_input_field_names.includes('subject_knowledge')"
                        @showCommentModal="showCommentModal"
                    />
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="methodological_knowledge" 
                        input_field_name="methodological_knowledge"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.learning_environment_and_target_group.field_methodological_knowledge"
                        :unresolved_comment="unresolved_input_field_names.includes('methodological_knowledge')"
                        :commented="commented_input_field_names.includes('methodological_knowledge')"
                        @showCommentModal="showCommentModal"
                    />
                </div>

                <div class="teachers-belief">
                    <h3 
                        class="basic-form-heading-2" 
                        v-html="resource[lang].information_about_target_group_and_teachers_beliefs.teachers_belief.heading" 
                    />
                    <p class="basic-form-text" v-html="resource[lang].information_about_target_group_and_teachers_beliefs.teachers_belief.text" />
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="expected_quality_of_learning_process" 
                        input_field_name="expected_quality_of_learning_process"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.teachers_belief.field_expected_quality_of_learning_process"
                        :unresolved_comment="unresolved_input_field_names.includes('expected_quality_of_learning_process')"
                        :commented="commented_input_field_names.includes('expected_quality_of_learning_process')"
                        @showCommentModal="showCommentModal"
                    />
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="expected_subject_specific_knowledge" 
                        input_field_name="expected_subject_specific_knowledge"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.teachers_belief.field_expected_subject_specific_knowledge"
                        :unresolved_comment="unresolved_input_field_names.includes('expected_subject_specific_knowledge')"
                        :commented="commented_input_field_names.includes('expected_subject_specific_knowledge')"
                        @showCommentModal="showCommentModal"
                    />
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="expected_methodological_knowledge" 
                        input_field_name="expected_methodological_knowledge"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.teachers_belief.field_expected_methodological_knowledge"  
                        :unresolved_comment="unresolved_input_field_names.includes('expected_methodological_knowledge')"
                        :commented="commented_input_field_names.includes('expected_methodological_knowledge')"
                        @showCommentModal="showCommentModal"
                    />
                    <InputTextArea :commentable="matrix_id !== null" :readonly="!update || disable"
                        v-model="criteria_for_success" 
                        input_field_name="criteria_for_success"
                        :label="resource[lang].information_about_target_group_and_teachers_beliefs.teachers_belief.field_criteria_for_success"  
                        :unresolved_comment="unresolved_input_field_names.includes('criteria_for_success')"
                        :commented="commented_input_field_names.includes('criteria_for_success')"
                        @showCommentModal="showCommentModal"
                    />
                </div>
            </div>

        </form>

        <CommentModal 
            v-if="showCommentModalFlag"
            :matrix="matrix" 
            :input_field_name="input_field_name"
            :use_sequence="false"
            @close="closeCommentModal"
        />

        <HelpModal
            v-if="showHelpModalFlag"
            :help_text="help_text"
            @close="closeHelpModal"
        />

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/matrix-form-resource'

import CommentModal from '@/components/modals/CommentModal'
import HelpModal from '@/components/modals/HelpModal'
import InputRadioButtons from '@/components/basic/InputRadioButtons'
import InputText from '@/components/basic/InputText'
import InputTextArea from '@/components/basic/InputTextArea'

import { promisePostRequest, promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    props: {
        matrix: Object,
        disable: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CommentModal,
        HelpModal,
        InputRadioButtons,
        InputText,
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang, 

            name: '',
            topic: '',
            course_nr: '',
            teachers: '',
            stage: '',
            status: '',
            substantive_concepts: '',
            historical_thinking_concepts: '',
            male_participants: 0,
            female_participants: 0,
            divers_participants: 0,
            target_group_composition: '',
            subject_knowledge: '',
            methodological_knowledge: '',
            expected_quality_of_learning_process: '',
            expected_subject_specific_knowledge: '',
            expected_methodological_knowledge: '',
            criteria_for_success: '',

            preliminary_temporal_framework: '',
            text_temporal_framework: '',

            temporal_framework_options: ['45 / 50 min', '90 / 100 min', 'Block (from - to)', 'Other'],

            // comment modal
            showCommentModalFlag: false,
            input_field_name: null,

            showHelpModalFlag: false,
            help_text: '',
            

            // abilities
            update: false,

            // for display of unresolved
            unresolved_input_field_names: [],
            commented_input_field_names: [],

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        temporal_framework() {
            return `${this.preliminary_temporal_framework}:${this.text_temporal_framework}`
        },
        matrix_id() {
            return this.$route.params.id ? this.$route.params.id : null;
        },
        unresolved_topic() {
            return 'topic' in this.unresolved_input_field_names;
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            if (this.matrix_id) {
                this.parseDataFromMatrixObject();
                this.getUnresolvedInputFields();
                this.getCommentedInputFields();
            }
            this.getAbilities();
        },
        async getAbilities() {
            if (this.matrix_id) {
                this.update = await getAbility('update-matrix', this.user, this.matrix)
            } else {
                this.update = true;
            }
        },
        getUnresolvedInputFields() {
            this.unresolved_input_field_names = this.matrix.comments
                .filter(comment => comment.resolved == false)
                .map(comment => comment.input_field_name);

            this.unresolved_input_field_names = [...new Set(this.unresolved_input_field_names)];
        },
        getCommentedInputFields() {
            this.commented_input_field_names = this.matrix.comments
                .map(comment => comment.input_field_name);

            this.commented_input_field_names = [...new Set(this.commented_input_field_names)];
        },

        // Requests
        createMatrixRequest() {
            const request_body = this.getRequestBodyFromData()
            return promisePostRequest('matrix', request_body)
        },
        updateMatrixRequest() {
            const request_body = this.getRequestBodyFromData()
            return promisePutRequest(`matrix/${this.matrix_id}`, request_body)
        },

        // Actions
        submit() {
            const input_items = document.querySelectorAll("input");

            let valid = true;
            for (let item of input_items) {
                if(!item.checkValidity()) {
                    valid = false;
                }
            }

            document.getElementById('matrix-form').classList.add('submitted'); 

            if (valid) {
                this.handleSubmit();
            } else {
                window.scrollTo(0, 0);
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].notifications.fill_fields,
                    color: 'negative'
                });
            }
            
        },
        async handleSubmit() {
            this.$store.dispatch('loading', true);
            if (this.matrix_id) {
                await this.updateMatrixRequest();
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].notifications.updated,
                    color: 'positive'
                });
                this.$emit('update')
            } else {
                const response = await this.createMatrixRequest();
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].notifications.created, 
                    color: 'positive'
                });
                this.$router.push(`/matrix/${response.data.index}`);
            }
            this.$store.dispatch('loading', false);
            this.$emit('reload');
        },
        showCommentModal(input_field_name) {
            if (this.update) {
                this.submit();
            }
            
            this.input_field_name = input_field_name
            this.showCommentModalFlag = true
            this.$store.dispatch('modal', true)
        },
        closeCommentModal(reload) {
            this.showCommentModalFlag = false
            this.input_field_name = null

            if (reload) {
                this.$emit('reload');
            }
        },
        showHelpModal(text) {
            this.showHelpModalFlag = true;
            this.help_text = text;
            this.$store.dispatch('modal', true);
        },
        closeHelpModal() {
            this.showHelpModalFlag = false;
            this.help_text = '';
        },

        // Functions
        getRequestBodyFromData() {
            const request_body = {
                name: this.name,
                topic: this.topic,
                course_nr: this.course_nr,
                teachers: this.teachers,
                stage: this.stage,
                status: this.status,
                substantive_concepts: this.substantive_concepts,
                historical_thinking_concepts: this.historical_thinking_concepts,
                male_participants: this.male_participants,
                female_participants: this.female_participants,
                divers_participants: this.divers_participants,
                target_group_composition: this.target_group_composition,
                subject_knowledge: this.subject_knowledge,
                methodological_knowledge: this.methodological_knowledge,
                expected_quality_of_learning_process: this.expected_quality_of_learning_process,
                expected_subject_specific_knowledge: this.expected_subject_specific_knowledge,
                expected_methodological_knowledge: this.expected_methodological_knowledge,
                criteria_for_success: this.criteria_for_success,
                temporal_framework: this.temporal_framework,
            }
            return request_body
        },
        parseDataFromMatrixObject() {
            this.name = this.matrix.name;
            this.topic = this.matrix.topic;
            this.course_nr = this.matrix.course_nr;
            this.teachers = this.matrix.teachers;
            this.stage = this.matrix.stage;
            this.status = this.matrix.status;
            this.substantive_concepts = this.matrix.substantive_concepts;
            this.historical_thinking_concepts = this.matrix.historical_thinking_concepts;
            this.male_participants = this.matrix.male_participants;
            this.female_participants = this.matrix.female_participants;
            this.divers_participants = this.matrix.divers_participants;
            this.target_group_composition = this.matrix.target_group_composition;
            this.subject_knowledge = this.matrix.subject_knowledge;
            this.methodological_knowledge = this.matrix.methodological_knowledge;
            this.expected_quality_of_learning_process = this.matrix.expected_quality_of_learning_process;
            this.expected_subject_specific_knowledge = this.matrix.expected_subject_specific_knowledge;
            this.expected_methodological_knowledge = this.matrix.expected_methodological_knowledge;
            this.criteria_for_success = this.matrix.criteria_for_success;

            if (this.matrix.temporal_framework) {
                this.preliminary_temporal_framework = this.matrix.temporal_framework.split(':', 1)[0]
                this.text_temporal_framework = this.matrix.temporal_framework.replace(`${this.preliminary_temporal_framework}:`, '')
            }
        }
    },
    watch: {
        matrix: {
            handler() {
                this.init();
            },
            deep: true,
        },
        user: function() {
            this.getAbilities();
        }
    },
    emits: ['update', 'reload']
}
</script>

<style lang="scss" scoped>

/* other */

.example-list {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    padding-inline-start: 3rem;

    list-style: '- ';
    list-style-position: inside;
}

.general > * + *,
.target-group > * + *,
.teachers-belief > * + * {
    margin-block-start: 1rem;
}

.target-group .grid {
    gap: 1rem;
}

.target-group-and-teachers-belief {
    margin-block-start: 3rem;
}

</style>