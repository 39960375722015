const resource = {
    en: {
        name: 'Name',
        user: 'User',
        matrix_info: 'Matrix Info'
    },
    de: {
        name: 'Name',
        user: 'Benutzer*in',
        matrix_info: 'Matrix Info'
    }
}

export default resource;