<template>
    <BaseModal ref="base_modal" @close="close">
        <label class="modal-heading" v-html="resource[lang].help" />
        <p class="modal-text center" v-html="help_text" />
        <div class="actions center">
            <Button color="white" :label="resource[lang].close" @click="close" />
        </div>
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/help-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'

export default {
    props: {
        help_text: String,
    },
    components: {
        BaseModal,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
    methods: {
        close() {
            this.$refs.base_modal.closeModal();
            this.$emit('close');
        }
    },
    emits: ['close']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 2rem;
}

@media (max-width: 40rem) {
    .actions > * {
        width: 100% !important;
    }

}

</style>