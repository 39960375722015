const resource = {
    en: {
        help: 'help',
        close: 'close',
    },
    de: {
        help: 'Hilfe',
        close: 'schließen',
    }
}

export default resource;