<template>
    <BaseModal wide ref="base_modal" @close="discard">
        <label class="modal-heading" v-html="resource[lang].heading" />
        <MatrixInfoCard :matrix="sequence.matrix" />

        <label class="sequence-heading" v-html="sequence_field.replace('_', ' ')" />
        <InputTextArea v-model="content" :label="resource[lang].add_content" />

        <div class="actions flex">
            <Button color="accent" :label="resource[lang].save" @click="save" />
            <Button color="white" :label="resource[lang].discard" @click="discard" />
        </div>
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/edit-sequence-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputTextArea from '@/components/basic/InputTextArea'
import MatrixInfoCard from '@/components/MatrixInfoCard'

export default {
    props: {
        sequence: {
            type: Object,
            required: true
        },
        sequence_field: {
            type: String,
            required: true
        },
    },
    components: {
        BaseModal,
        Button,
        InputTextArea,
        MatrixInfoCard
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            content: '',

            resource
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        // Init
        init() {
            this.content = this.sequence[this.sequence_field];
        },

        // Actions
        save() {
            this.$emit('save', this.content);
            this.$refs.base_modal.closeModal();
        },
        discard() {
            this.content = '';
            this.$refs.base_modal.closeModal();
            this.$emit('discard');
        },
    },
    watch: {
        sequence: function() {
            this.init();
        },
        sequence_field: function() {
            this.init();
        }
    },
    emits: ['discard', 'save']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 1rem;
    justify-content: space-between;
}

.sequence-heading {
    display: block;

    color: var(--accent);

    font-family: var(--standard-font);
    font-size: 1.25rem;
    font-weight: 600;

    text-transform: uppercase;

    margin-block-start: 2rem;
    margin-block-end: 1rem;
}

@media (max-width: 40rem) {
    .actions > * {
        width: 100% !important;
    }

    .actions > * + * {
        margin: 0;
        margin-block-start: 1rem;
    }
}

</style>