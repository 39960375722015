const resource = {
    en: {
        heading: 'Comment on Matrix',
        comments: 'Comments',
        no_comments: 'No Comments',
        write_a_comment: 'Write a Comment',
        comment_noun: 'Comment',
        comment_verb: 'Comment',
        discard: 'Discard',
    },
    de: {
        heading: 'Kommentare der Matrix',
        comments: 'Kommentare',
        no_comments: 'Keine Kommentare',
        write_a_comment: 'Hinterlasse einen Kommentar',
        comment_noun: 'Kommentar',
        comment_verb: 'Kommentieren',
        discard: 'Verwerfen',
    }
}

export default resource;