const resource = {
    en: {
        heading: 'Matrix for designing the course in detailed sequences',
        add_sequence: 'add sequence',
        notification: {
            create_first: 'Please save the matrix before adding sequences'
        },
        matrix: {
            1: {
                heading: 'Organisational Structure',
                text: 'Time, Function in learning process, settings',
            },
            2: {
                heading: 'Aims, Rationales',
                text: 'Historical Thinking Concept'
            },
            3: {
                heading: 'Topics Sub-Themes',
                text: 'Substantive Concept'
            },
            4: {
                heading: 'Structure of Communication / Learning Organisation',
                text: 'Methods, Media'
            },
            5: {
                heading: 'Analysis, Interpretation, Transfer',
                text: 'Competence-building, Orientation'
            },
            6: {
                heading: 'Back-Coupling',
                text: 'Forms of Feedback, Reference to Learning Group'
            },
            7: {
                heading: 'Reflection',
                text: 'In Group and Self-Reflection'
            },
        },
        tooltips: {
            move_up: 'move sequence up',
            move_down: 'move sequence down',
            delete: 'delete sequence',
            show_help: 'Show Help',
        }
    },
    de: {
        heading: 'Matrix zur Planung (Sequenzierung) der Unterrichtsstunde',
        add_sequence: 'Sequenz einfügen',
        notification: {
            create_first: 'Bitte speichern Sie die Matrix bevor Sie Sequenzen einfügen'
        },
        matrix: {
            1: {
                heading: 'Organisations-struktur',
                text: 'Zeit, Funktion im Lernprozess, räumliche Arrangements'
            },
            2: {
                heading: 'Ziele, Prinzipien',
                text: 'Aspekte / Konzepte des Historischen Denkens'
            },
            3: {
                heading: 'Themenwahl',
                text: 'Inhalte, Fragestellungen'
            },
            4: {
                heading: 'Kommunikations-strukturen / Lernorganisation',
                text: 'Methoden, Medien'
            },
            5: {
                heading: 'Analyse, Interpretation, Transfer',
                text: 'Kompetenzaufbau, Anwendung, Überprüfung, Evaluation'
            },
            6: {
                heading: 'Rückkopplung',
                text: 'Beziehungsstruktur, Kommunikation, Feedback, Selbstrefeereenz der Lerngruppe'
            },
            7: {
                heading: 'Reflexion',
                text: 'In der Gruppe und Selbstreflexion, Historische Sinnbildung und Identitätsstiftung'
            },
        },
        tooltips: {
            move_up: 'Sequenz nach oben bewegen',
            move_down: 'Sequenz nach unten bewegen',
            delete: 'Sequenz löschen',
            show_help: 'Hilfe anzeigen',
        }
    }
}

export default resource;