const resource = {
    en: {
        comment: 'comment field',
        commented: 'comments available on this field',
        unresolved: 'unresolved comments available',
    },
    de: {
        comment: 'comment field',
        commented: 'comments available on this field',
        unresolved: 'unresolved comments available',
    }
}

export default resource;