<template>
    <div :class="['comment', comment.resolved ? '' : 'unresolved']">
        <p class="comment-label" v-html="comment.author.full_name" />
        <p class="comment-description" v-html="`${resource[lang].commented_on} ${date}`" />
        <p class="comment-text" v-html="comment.comment" />
        <InputTextArea 
            class="text-area" 
            v-if="in_edit" 
            :label="resource[lang].updated_comment" 
            v-model="updated_comment" 
        />
        <div class="actions">
            <button v-if="!in_edit && edit" v-html="resource[lang].edit" @click="showEditField" />

            <button v-if="in_edit && delet" v-html="resource[lang].delete" @click="$emit('deleteComment', comment.id)"/>
            <button v-if="in_edit" v-html="resource[lang].discard" @click="discard" />
            <button v-if="in_edit" v-html="resource[lang].update" @click="update" />
            
            <button v-if="!comment.resolved && !in_edit && resolve" v-html="resource[lang].resolve" @click="$emit('resolveComment', comment.id)" />
            <button v-if="comment.resolved && !in_edit && resolve" v-html="resource[lang].unresolve" @click="$emit('unresolveComment', comment.id)" />
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/comment-resource'

import InputTextArea from '@/components/basic/InputTextArea'

import { getAbility } from '@/assets/js/user-abilities'

export default {
    inject: ['$dateOptions'],
    props: {
        comment: {
            type: Object,
            required: true
        }
    },
    components: {
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            in_edit: false,
            updated_comment: this.comment.comment,

            // user abilities
            edit: false,
            delet: false,
            resolve: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        date() {
            return new Date(this.comment.updated_at).toLocaleDateString('en-GB', this.$dateOptions);
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.getUserAbilities();
        },
        async getUserAbilities() {
            this.resolve = await getAbility('resolve-matrix-comment', this.user, this.comment);
            this.edit = await getAbility('update-matrix-comment', this.user, this.comment);
            this.delet = await getAbility('delete-matrix-comment', this.user, this.comment);
        },

        // Actions
        showEditField() {
            this.in_edit = true;
        },
        discard() {
            this.updated_comment = '';
            this.in_edit = false;
        },
        update() {
            this.$emit('updateComment', this.comment.id, this.updated_comment);
            this.updated_comment = '';
            this.in_edit = false;
        }
    },
    watch: {
        comment: function() {
            this.getUserAbilities();
        },
        user: function() {
            this.getUserAbilities();
        }
    },
    emits: ['resolveComment', 'unresolveComment', 'deleteComment', 'updateComment']
}
</script>

<style lang="scss" scoped>

.comment {
    position: relative;
    background-color: var(--whit);
    padding: 1rem;
}

.comment-description {
    color: var(--dark_grey);

    font-family: var(--standard-font);
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2;

    margin-bottom: 2rem;
}

.actions {
    
    position: absolute;
    top: 1rem;
    right: 1rem;

    & > * {
        background-color: var(--whit);
        border: none;

        cursor: pointer;

        color: var(--accent);
        font-family: var(--standard-font);
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.2;
        text-decoration: underline;
        text-transform: uppercase;
    }

    & > * + * {
        margin-inline-start: 1rem;
    }
}

.actions > *:hover, .actions > *:focus-visible {
    font-weight: 500;
    text-decoration: none;
}

.text-area {
    margin-block-start: 1rem;
    border-top: solid 1px var(--blak);
}

.unresolved::after {
    content: ' ';
    position: absolute;
    left: -8px;
    top: 0;

    height: 100%;
    width: 4px;
    background-color: var(--light_blue);
}

</style>