const resource = {
    en: {
        general_information: {
            heading: 'General Information',
            field_name: 'Name of this Planungsmatrix',
            field_topic: 'Topic',
            field_course_nr: 'Course Nr. / Module Nr.',
            field_temporal_framework: 'Temporal Framework',
            field_teachers: 'Author(s) / Teacher(s)',
            field_stage: 'Stage / Level',
            emphasis_on_substantive_concepts: {
                heading: 'Emphasis on substantive concept(s)',
                text: 'Use this field to indicate the substantive concept(s) which should be elaborated / strengthened during this course:',
                list: {
                    text: 'For example:',
                    list_items: [
                        'concept of power, domain, democracy, dictatorship, revolution, fascism…',
                        'feudal-system, industrialisation, distribution of ressources…',
                        'social stratification, diversity, gender, conflict…',
                        'culture, renaissance, age of enlightenment, modernity…'
                    ]
                },
                field_substantive_concepts: 'Selected Concept (incl. reasons for decision of this concept)'
            },
            emphasis_on_historical_thinking_concepts: {
                heading: 'Emphasis on Historical Thinking Concept(s)',
                text: 'Use this field to indicate concepts(s) of historical thinking [the second-order concept(s)] which should be elaborated / strengthened during this course:',
                list: {
                    text: 'For example:',
                    list_items: [
                        'Concepts of historical sense-making: traditional, exemplary, critical, genetic (J. Rüsen); identity, historical consciousness',
                        'Historical thinking concepts: evidence, significance, perspective, cause and effect, continuity and change, ethical aspect (Seixas)',
                        'Competences of historical learning: narrative c., interpretative c., methodological c., (H.-J. Pandel, P. Gautschi, C. van Boxtel…)',
                        'other'
                    ]
                },
                field_historical_thinking_concepts: 'Selected Concept (incl. reasons for decision of this concept)'
            }
        },
        information_about_target_group_and_teachers_beliefs: {
            heading: 'Information about the target group and the teacher’s beliefs',
            learning_environment_and_target_group: {
                heading: 'Learning Environment and Target Group',
                text: 'Please describe <strong>empirically comprehensible aspects</strong> about the target group (as far as available) which will help you to plan the concrete university course by referring to the substantive concepts and historical thinking concepts identified and described above.',
                field_male_participants: 'Male Participants',
                field_female_participants: 'Female Participants',
                field_divers_participants: 'Divers Participants',
                field_target_group_composition: 'Composition of the target group',
                field_subject_knowledge: 'Expected subject knowledge following the status in the curriculum',
                field_methodological_knowledge: 'Methodological Knowledge',
            },
            teachers_belief: {
                heading: 'Teacher’s Beliefs, Expectations, Criteria for successful work',
                text: 'Please describe relevant <strong>aspects of your beliefs about the target group</strong>, your expectations and criteria for successful work during this course.',
                field_expected_quality_of_learning_process: 'Expected quality of the learning process when looking at the composition of the target group',
                field_expected_subject_specific_knowledge: 'Expected subject specific knowledge following your experiences with TE courses of this type',
                field_expected_methodological_knowledge: 'Expected methodological knowledge following your experiences with TE courses of this type',
                field_criteria_for_success: 'Please describe 3 (min) to 5 (max) criteria for the success when working on this topic with this group'
            }
        },
        notifications: {
            updated: 'The Matrix Information was Updated',
            created: 'The Matrix has been Created',
            fill_fields: 'Please fill in the required field'
        },
        help_texts: {
            field_target_group_composition: 'gender relation, social background, cultural background and other relevant aspects of diversity',
            field_expected_quality_of_learning_process: 'e.g. related to gender relation, to social and cultural background, to diversity aspects',
            field_stage: 'Please indicate the level of formation: e.g. Grammar School: Secondary I, 2nd grade; e.g. University: Teacher Education, advanced level',
            field_course_nr: 'Please indicate which university course, seminar or project is related'
        },
        status_options: {
            in_edit: 'IN EDIT',
            for_review: 'FOR REVIEW',
            public: 'PUBLIC',
            finish: 'FINISH',
        }
    },
    de: {
        general_information: {
            heading: 'Allgemeine Informationen',
            field_name: 'Name der Planungsmatrix',
            field_topic: 'Thema',
            field_course_nr: 'Kursbezeichnung',
            field_temporal_framework: 'Zeitlicher Rahmen',
            field_teachers: 'Autor*in(nen) / Lehrer*in(nen)',
            field_stage: 'Schulstufe / Bezug zum Lehrplan',
            emphasis_on_substantive_concepts: {
                heading: 'Schwerpunktsetzung im Bereich der "Substantive Concepts"',
                text: '<strong>OBLIGATORISCH:</strong> Beschreiben Sie, welche(s) “First-Order”-Konzept(e) während des Unterrichts entwickelt/ausgebaut werden soll(en). ',
                list: {
                    text: 'Beispiele:',
                    list_items: [
                        'Macht, Herrschaft, Demokratie, Diktatur, Revolution, Faschismus etc.',
                        'Feudalsystem, Industrialisierung, Ressourcenverteilung etc.',
                        'Gesellschaftsschichten, Diversität, Gender, Konflikte/Konfliktbewältigung etc.',
                        'Kultur, Renaissance, Aufklärung, Moderne etc.'
                    ]
                },
                field_substantive_concepts: 'Ausgewähltes Konzept inkl. Begründung für die Entscheidung für dieses Konzept (mit Literaturverweisen)'
            },
            emphasis_on_historical_thinking_concepts: {
                heading: 'Aspekte des Historischen Denkens (Wissensschafts- und Erkenntnistheorie)',
                text: '<strong>OBLIGATORISCH:</strong> Beschreiben Sie, welche Aspekte des Historischen Denkens (bzw. des historisch-politischen oder des historisch-sozialwissenchaftlichenDenkens) Sie in dieser/n Unterrichtseinheit/en fördern wollen: z.B. ',
                list: {
                    text: 'Beispiele:',
                    list_items: [
                        'Signifikanz (Relevanz, Gegenwartsbezug, historische Fragestellungen)',
                        'Evidenz (Quellen- u. Sachbezug, Quellenkritik, historischer Kontext, Wissenschaftsdimension)',
                        'Perspektivität (Autoriinnen/Autoren; Raum-Zeit-Bezug; Anfang u. Ende des hist. Narrativs, Erzählperspektiven)',
                        'Kausalität (Logiken der Begründung von historichen Narrativen; Theoriebezug, Forschungsbezug)',
                        'Kontinuität und Wandel (Entwicklungen, Strukturen, „eingefrorene“ Konflikte, Reformen, Revolutionen)',
                        'Ethische Aspekte (Normen, Werte, Regeln d. Zusammenlebens, Ideologien/-kritik, Konfliktmoderation u. Konfliktlösungen)',
                    ]
                },
                field_historical_thinking_concepts: 'Ausgewähltes Konzept inkl. Begründung für die Entscheidung für dieses Konzept (mit Literaturverweisen)'
            }
        },
        information_about_target_group_and_teachers_beliefs: {
            heading: 'Informationen über die Lerngruppe, die Lernumgebung & Beschreibung der relevanten Überzeugungen/Vorstellungen der Lehrperson(en) in Bezug auf die Lern-gruppe (= Teacher‘s Beliefs)',
            learning_environment_and_target_group: {
                heading: 'Lernumgebung und Lerngruppe',
                text: 'Beschreiben Sie empirisch nachvollziehbare Aspekte über die Lerngruppe (soweit möglich), die Ihnen helfen, den Unterricht für eine konkrete Klasse/Schüler*innengruppe zu planen – mit Bezug auf die oben beschriebenen "Substantive Concepts" und Aspekte des historischen Denkens',
                field_male_participants: 'Männliche Lernende',
                field_female_participants: 'Weibliche Lernende',
                field_divers_participants: 'Diverse Lernende',
                field_target_group_composition: 'Zusammensetzung der Lerngruppe',
                field_subject_knowledge: 'Vorwissen der Lernenden (in Bezug auf den Lehrplan oder auf die Lerngeschichte dieser Lerngruppe) ',
                field_methodological_knowledge: 'Methodische Kenntnisse',
            },
            teachers_belief: {
                heading: 'Teacher‘s Beliefs, Erwartungen, Kriterien für einen erfolgreichen Unterricht',
                text: 'Beschreiben Sie relevante Aspekte Ihrer Annahmen über die Lerngruppe (Teacher’s Beliefs), Ihre Erwartungen und Kriterien, die für einen erfolgreichen Unterricht Voraussetzung sind. ',
                field_expected_quality_of_learning_process: 'Annahmen über die Qualität des Lernprozesses',
                field_expected_subject_specific_knowledge: 'Zu erwartendes Vorwissen der Schüler*innen hinsichtlich Ihrer Erfahrungen mit dieser Schüler*innengruppe in dieser Schulstufe.',
                field_expected_methodological_knowledge: 'Zu erwartende methodische Kenntnisse der Schüler*innen hinsichtlich Ihrer Erfahrungen mit dieser Schüler*innengruppe in dieser Schulstufe.',
                field_criteria_for_success: 'Nennen Sie drei bis fünf Kriterien, die über den Erfolg der Unterrichtseinheit, wenn Sie mit genau dieser beschriebenen Lerngruppe an dem von Ihnen ausgewählten Thema arbeiten:'
            }
        },
        notifications: {
            updated: 'Die Matrix wurde aktualisiert',
            created: 'Die Matrix wurde erstellt',
            fill_fields: 'Bitte füllen Sie das benötigte Feld aus'
        },
        help_texts: {
            field_target_group_composition: 'Gender Verhältnis, sozialer und kultureller Hintergrund sowie andere relevante Aspekte',
            field_expected_quality_of_learning_process: 'Zum Beispiel im Hinblick auf die Zusammensetzung der Lerngruppe: Gender Verhältnis, sozialer und kultureller Hintergrund, Diversität',
            field_stage: 'Definieren Sie hier für welche Lernstufe die Planung erstellt wurde. z.B. 2. Klasse NMS, 7.Klasse AHS, Uni-Kurs Masterausbildung',
            field_course_nr: 'Geben Sie hier ein, welchem Uni-Kurs, welchem Seminar oder welchem Projekt die Planung zugeordnet werden soll.'
        },
        status_options: {
            in_edit: 'IN BEARBEITUNG',
            for_review: 'FÜR REVIEW',
            public: 'ÖFFENTLICH',
            finish: 'VOLLENDET',
        }
    },   
}

export default resource;