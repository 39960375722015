const resource = {
    en: {
        heading: 'Edit Sequence',
        add_content: 'Add content...',
        save: 'Save',
        discard: 'Discard'
    },
    de: {
        heading: 'Sequenz bearbeiten',
        add_content: 'Inhalt einfügen...',
        save: 'Speichern',
        discard: 'Änderungen verwerfen'
    }
}

export default resource;