const resource = {
    en: {
        commented_on: 'commented on',
        resolve: 'resolve',
        unresolve: 'mark as unresolved',
        delete: 'delete',
        edit: 'edit',
        updated_comment: 'Updated Comment',
        update: 'Update',
        discard: 'Discard',
    },
    de: {
        commented_on: 'kommentiert am',
        resolve: 'als gelöst markieren',
        unresolve: 'als ungelöst markieren',
        delete: 'löschen',
        edit: 'bearbeiten',
        updated_comment: 'Neuer Kommentar',
        update: 'Änderung Bestätigen',
        discard: 'Änderung Verwerfen',
    }
}

export default resource;