<template>
    <div>
        <label v-html="label" />

        <label :for="label" v-for="(label, index) in labels" :key="label">
            <input 
                type="radio" 
                name="radio" 
                :id="label" 
                :value="options[index]" 
                @input="$emit('update:modelValue', $event.target.value)" 
                :disabled="readonly"
            >
            <span v-html="label" />
        </label>

    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
        options: Array,
        labels: Array,
        label: String,
        readonly: Boolean,
    },
    mounted() {
        const radio_buttons = document.querySelectorAll('input[type=radio]');
    
        for (let button of radio_buttons) {
            if (button.value == this.modelValue) {
                button.checked = true;
            }
        }
    },
    emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>

div {
    padding-block-start: 1.25rem;
    padding-block-end: 1.25rem;
}

input {
    display: none;
}

label {
    display: inline-block;
    cursor: pointer;

    color: var(--dark_grey);

    font-family: var(--standard-font);
    font-size: 1.25rem;
    font-weight: 500;

    line-height: 1.2;

    margin-block-end: 2rem;
}

label:hover {
    color: var(--blak);
}

label:first-of-type {
    padding-inline-end: 2.5rem;
}

label + label {
    padding-inline-start: 4rem;
}

label span {
    position: relative;
    line-height: 1.2;
}

label span:before,
label span:after {
    content: '';
}

label span:before {
    background-color: var(--whit);
    border: 1px solid var(--dark_grey);
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
}

label:hover span:before {
    border-width: 2px;
}

label span:after {
    background: var(--dark_grey);
    width: 1.5rem;
    height: 1.5rem;
    transition: 300ms;
    position: absolute;
    top: 1px;
    left: 4px;
    opacity: 0;
}

label input:checked+span:after {
    opacity: 1;
}

</style>