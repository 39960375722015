<template>
    <div class="wrapper">
        <div class="basic-form">
            <h2 class="basic-form-heading-1" v-html="resource[lang].heading" />

            <div class="matrix-header grid">
                <header v-for="n in 7" :key="n" v-html="n" />
                <div v-for="n in 7" :key="n" class="header">
                    <h3 v-html="resource[lang].matrix[n].heading" />
                    <p v-html="resource[lang].matrix[n].text" />
                    
                    <q-icon 
                        v-if="resource[lang].matrix[n].help_text"
                        name="fas fa-question" 
                        size="1rem" 
                        color="dark_grey"
                        class="help-button"
                        tabindex="0"
                        @click="showHelpModal(resource[lang].matrix[n].help_text)"
                    >
                        <q-tooltip v-html="resource[lang].tooltips.show_help" />
                    </q-icon>
                </div>
            </div>
            <div class="matrix grid" v-for="(sequence, index) in sequences" :key="sequence.id" tabindex="0">
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.organisational_structure"
                    :commented="!disable && sequence.commented_input_field_names.includes('organisational_structure')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('organisational_structure')"
                    @showEdit="showEditSequenceModal(sequence, 'organisational_structure')"
                    @showCommentModal="showCommentModal(sequence, 'organisational_structure')"
                />
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.aims_rationales"
                    :commented="!disable && sequence.commented_input_field_names.includes('aims_rationales')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('aims_rationales')"
                    @showEdit="showEditSequenceModal(sequence, 'aims_rationales')"
                    @showCommentModal="showCommentModal(sequence, 'aims_rationales')"
                />
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.topics_sub_themes"
                    :commented="!disable && sequence.commented_input_field_names.includes('topics_sub_themes')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('topics_sub_themes')"
                    @showEdit="showEditSequenceModal(sequence, 'topics_sub_themes')"
                    @showCommentModal="showCommentModal(sequence, 'topics_sub_themes')"
                />
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.structure_of_communication"
                    :commented="!disable && sequence.commented_input_field_names.includes('structure_of_communication')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('structure_of_communication')"
                    @showEdit="showEditSequenceModal(sequence, 'structure_of_communication')"
                    @showCommentModal="showCommentModal(sequence, 'structure_of_communication')"
                />
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.analysis_interpretation_transfer"
                    :commented="!disable && sequence.commented_input_field_names.includes('analysis_interpretation_transfer')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('analysis_interpretation_transfer')"
                    @showEdit="showEditSequenceModal(sequence, 'analysis_interpretation_transfer')"
                    @showCommentModal="showCommentModal(sequence, 'analysis_interpretation_transfer')"
                />
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.back_coupling"
                    :commented="!disable && sequence.commented_input_field_names.includes('back_coupling')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('back_coupling')"
                    @showEdit="showEditSequenceModal(sequence, 'back_coupling')"
                    @showCommentModal="showCommentModal(sequence, 'back_coupling')"
                />
                <MatrixSequencePart
                    v-if="sequence.processed"
                    :content="sequence.reflection"
                    :commented="!disable && sequence.commented_input_field_names.includes('reflection')"
                    :unresolved_comment="!disable && sequence.unresolved_input_field_names.includes('reflection')"
                    @showEdit="showEditSequenceModal(sequence, 'reflection')"
                    @showCommentModal="showCommentModal(sequence, 'reflection')"
                />
                <q-icon 
                    v-if="update"
                    name="fas fa-times" 
                    size="2rem" 
                    color="dark_grey"
                    class="delete-button"
                    tabindex="0"
                    @click="showDeleteModal(sequence.id)"
                >
                    <q-tooltip v-html="resource[lang].tooltips.delete" />
                </q-icon>
                <q-icon 
                    v-if="index != 0 && update"
                    name="fas fa-arrow-up" 
                    size="2rem" 
                    color="dark_grey"
                    class="move-button move-up-button"
                    tabindex="0"
                    @click="moveSequenceUp(index)"
                >
                    <q-tooltip v-html="resource[lang].tooltips.move_up" />
                </q-icon>
                <q-icon 
                    v-if="index != sequences.length - 1 && update"
                    name="fas fa-arrow-down" 
                    size="2rem" 
                    color="dark_grey"
                    class="move-button move-down-button"
                    tabindex="0"
                    @click="moveSequenceDown(index)"
                >
                    <q-tooltip v-html="resource[lang].tooltips.move_down" />
                </q-icon>
            </div>

            <Button 
                v-if="update"
                class="add-button" 
                color="primary" 
                :label="resource[lang].add_sequence" 
                @click="createNewSequence" 
            />
        </div>

        <DeleteModal 
            v-if="showDeleteModalFlag" 
            @confirm="deleteSequence"
            @cancel="cancelDelete"
        />

        <EditSequenceModal 
            v-if="showEditSequenceModalFlag"
            :sequence="edit_sequence"
            :sequence_field="edit_sequence_field"
            @save="saveSequence"
            @discard="discardSequence"
        />

        <CommentModal 
            v-if="showCommentModalFlag"
            :sequence="comment_sequence" 
            :input_field_name="comment_input_field_name"
            :use_sequence="true"
            @close="closeCommentModal"
        />

        <HelpModal
            v-if="showHelpModalFlag"
            :help_text="help_text"
            @close="closeHelpModal"
        />

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/matrix-sequences-resource'

import Button from '@/components/basic/Button'
import CommentModal from '@/components/modals/CommentModal'
import DeleteModal from '@/components/modals/DeleteModal'
import EditSequenceModal from '@/components/modals/EditSequenceModal'
import HelpModal from '@/components/modals/HelpModal'
import MatrixSequencePart from '@/components/MatrixSequencePart'

import { promiseDeleteRequest, promisePostRequest, promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    props: {
        matrix: Object,
        disable: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Button,
        CommentModal,
        DeleteModal,
        EditSequenceModal,
        HelpModal,
        MatrixSequencePart,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            sequences: [],

            showCommentModalFlag: false,
            showEditSequenceModalFlag: false,
            showDeleteModalFlag: false,
            showHelpModalFlag: false,
            help_text: '',

            delete_sequence_id: null,

            edit_sequence: null,
            edit_sequence_field: null,

            comment_sequence: null,
            comment_input_field_name: null,

            // abilities
            update: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
        matrix_id() {
            return this.$route.params.id ? this.$route.params.id : '';
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            if (this.matrix_id) {
                this.sequences = this.matrix.sequences;
                this.getUnresolvedInputFields();
                this.getCommentedInputFields();
            } 
            this.getAbilities();
            if (this.disable) this.pushEmptySequencesForDisable();
        },
        async getAbilities() {
            if (this.matrix_id) {
                this.update = await getAbility('update-matrix', this.user, this.matrix)
            }
        },
        getUnresolvedInputFields() {
            this.sequences = this.sequences.map(sequence => {
                const unresolved_input_field_names = sequence.comments
                    .filter(comment => comment.resolved == false)
                    .map(comment => comment.input_field_name);

                this.unresolved_input_field_names = [...new Set(this.unresolved_input_field_names)];

                return {
                    ...sequence,
                    unresolved_input_field_names
                }
            });
        },
        getCommentedInputFields() {
            this.sequences = this.sequences.map(sequence => {
                const commented_input_field_names = sequence.comments
                    .map(comment => comment.input_field_name);

                this.commented_input_field_names = [...new Set(this.commented_input_field_names)];

                return {
                    ...sequence,
                    commented_input_field_names,
                    processed: true
                }
            });
        },

        // Requests
        createSequenceRequest(request_body) {
            return promisePostRequest('class-sequence', request_body)
        },
        saveSequenceRequest(request_body) {
            return promisePutRequest(`class-sequence/${request_body.id}`, request_body)
        },
        deleteSequenceRequest(id) {
            return promiseDeleteRequest(`class-sequence/${id}`)
        },

        // Actions
        showEditSequenceModal(sequence, sequence_field) {
            if (this.update) {
                this.edit_sequence = sequence
                this.edit_sequence_field = sequence_field

                this.showEditSequenceModalFlag = true
                this.$store.dispatch('modal', true)
            }
        },
        showDeleteModal(id) {
            this.delete_sequence_id = id

            this.showDeleteModalFlag = true
            this.$store.dispatch('modal', true)
        },
        saveSequence(content) {
            const index = this.sequences.findIndex(sequence => sequence.id === this.edit_sequence.id);

            this.sequences[index][this.edit_sequence_field] = content;

            this.$store.dispatch('loading', true)
            this.saveSequenceRequest(this.sequences[index])
            this.$store.dispatch('loading', false)

            this.showEditSequenceModalFlag = false;
        },
        discardSequence() {
            this.edit_sequence = null;
            this.edit_sequence_field = null;
            this.showEditSequenceModalFlag = false;
        },
        cancelDelete() {
            this.delete_sequence_id = null;
            this.showDeleteModalFlag = false;
        },
        async deleteSequence() {
            this.$store.dispatch('loading', true)
            this.deleteSequenceRequest(this.delete_sequence_id)
            this.$store.dispatch('loading', false)

            this.sequences = this.sequences.filter(sequence => sequence.id !== this.delete_sequence_id);
            this.delete_sequence_id = null;
        },
        async moveSequenceUp(index) {
            if (index > 1) {
                this.sequences[index].order = (this.sequences[index-2].order + this.sequences[index-1].order) / 2.0;
            } else {
                this.sequences[index].order = this.sequences[index-1].order / 2.0;
            }

            this.$store.dispatch('loading', true);
            await this.saveSequenceRequest(this.sequences[index])
            this.$store.dispatch('loading', false);
            
            this.sortSequences();
        },
        async moveSequenceDown(index) {
            if (index < this.sequences.length - 2) {
                console.log('if')
                this.sequences[index].order = (this.sequences[index+2].order + this.sequences[index+1].order) / 2.0;
            } else {
                console.log('else')
                this.sequences[index].order = this.sequences[index+1].order + 1.0;
            }

            this.$store.dispatch('loading', true);
            await this.saveSequenceRequest(this.sequences[index]);
            this.$store.dispatch('loading', false);

            this.sortSequences()
        },
        showCommentModal(sequence, input_field_name) {
            if (this.update) {
                this.$emit('save');
            }
            // save matrix
            this.comment_input_field_name = input_field_name;
            this.comment_sequence = sequence;
            this.showCommentModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        closeCommentModal(reload) {
            this.showCommentModalFlag = false;
            this.comment_sequence = null;
            this.comment_input_field_name = null;

            if (reload) {
                this.$emit('reload');
            }
        },
        showHelpModal(text) {
            this.showHelpModalFlag = true;
            this.help_text = text;
            this.$store.dispatch('modal', true);
        },
        closeHelpModal() {
            this.showHelpModalFlag = false;
            this.help_text = '';
        },

        // Functions
        async createNewSequence() {
            if (!this.matrix_id) {
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].notifications.create_first,
                    color: 'negative'
                });
            } else {
                this.$emit('save');
                const sequence = {
                    organisational_structure: '',
                    aims_rationales: '',
                    topics_sub_themes: '',
                    structure_of_communication: '',
                    analysis_interpretation_transfer: '',
                    back_coupling: '',
                    reflection: '',
                    matrix_id: this.matrix_id
                }

                if (this.sequences.length > 0) {
                    sequence.order = this.sequences[this.sequences.length - 1].order + 1;
                } else {
                    sequence.order = 1;
                }

                this.$store.dispatch('loading', true);
                await this.createSequenceRequest(sequence);
                this.$store.dispatch('loading', false);
                this.$emit('reload');
            }
        },
        sortSequences() {
            this.sequences.sort(function(a, b) {
                return a.order - b.order;
            });
        },
        pushEmptySequencesForDisable() {
            const sequence = {
                organisational_structure: '',
                aims_rationales: '',
                topics_sub_themes: '',
                structure_of_communication: '',
                analysis_interpretation_transfer: '',
                back_coupling: '',
                reflection: '',
                processed: true,
            }

            this.sequences.push(sequence);
            this.sequences.push(sequence);
        }
    },
    watch: {
        matrix: {
            handler() {
                this.init();
            },
            deep: true,
        },
        user: function() {
            this.getAbilities();
        }
    },
    emits: ['reload', 'save'],
}
</script>

<style lang="scss" scoped>

.add-button {
    margin-block-start: 2rem;
}

.basic-form {
    overflow-x: auto;
}

.delete-button {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;

    padding: 0.5rem;

    cursor: pointer;

    display: none;
}

.delete-button:hover,
.delete-button:focus-visible {
    color: var(--blak) !important;
}

.help-button {
    position: absolute;

    right: 0;
    bottom: 0;

    padding: 0.5rem;

    cursor: pointer;
}

.help-button:hover,
.help-button:focus-visible {
    color: var(--blak) !important;
}

.matrix {
    min-width: 88rem;
    margin-inline-start: -2rem;
    margin-inline-end: -2rem;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;

    position: relative;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    background-color: var(--light_grey);
    gap: 0;

    & > div {
        cursor: pointer;
        
        border: solid 1px var(--primary);
        background-color: var(--whit);
        height: 100%;
        min-height: 5rem;

        padding: 0.5rem;

        font-family: 'Open Sans';
        font-size: 1rem;
    }

    &:hover .delete-button,
    &:focus-visible .delete-button,
    &:hover .move-button,
    &:focus-visible .move-button {
        display: block;
    }
}

.matrix-header {
    min-width: 88rem;
    margin-inline-start: -2rem;
    margin-inline-end: -2rem;
    padding-inline-start: 2rem;
    padding-inline-end: 2rem;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    background-color: var(--light_grey);
    gap: 0;

    & > * {
        padding: 0.5rem;
        border: solid 1px var(--primary);
        height: 100%;
        background-color: var(--whit);
    }

    header {
        color: var(--blak);
        background-color: #F1F2FC;

        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.2;

        text-align: center;
    }

    .header {
        position: relative;

        color: var(--blak);
        background-color: #F1F2FC;
        border-bottom: solid 2px var(--blak);

        text-align: center;
        padding-block-start: 1rem;
        padding-block-end: 1rem;

        h3 {
            font-family: var(--standard-font);
            font-size: 1.25rem;
            font-weight: 500;

            line-height: 1.4;

            word-break: break-word;
        } 

        p {
            font-family: var(--standard-font);
            font-size: 1rem;
            font-weight: 400;

            line-height: 1.4;

            margin-top: 1rem;
        }
        
    }

}

.move-button {
    position: absolute;
    left: -0.5rem;

    padding: 0.5rem;

    cursor: pointer;

    display: none;

    &.move-up-button {
        top: 0;
    }

    &.move-down-button {
        bottom: 0;
    }
}

.move-button:hover {
    color: var(--blak) !important;
}

</style>