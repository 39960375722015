<template>
    <div class="matrix-info" tabindex="0">
        <div class="grid">
            <label class="label" v-html="`${resource[lang].name}:`" />
            <label class="value" v-html="matrix.name" />
            <label class="label" v-html="`${resource[lang].user}:`" />
            <label class="value" v-html="matrix.owner.full_name" />
        </div>
        <label v-html="resource[lang].matrix_info" />
    </div>
</template>

<script>
import resource from '@/assets/resources/matrix-info-card-resource'

export default {
    props: {
        matrix: {
            type: Object,
            required: true
        }
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    }
}
</script>

<style lang="scss" scoped>

.grid {
    column-gap: 1rem;
    row-gap: 0.5rem;
    grid-template-columns: 1fr 2fr;

    label {
        color: var(--blak);

        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 400;
    }

    .value {
        max-width: 40ch;
    }
}

.matrix-info {
    position: relative;

    background-color: var(--whit);
    padding: 1rem;

    & > label {
        position: absolute;
        right: 1rem;
        top: 1rem;

        color: var(--accent);

        font-family: var(--standard-font);
        font-size: 1rem;
        font-weight: 500;

        text-transform: uppercase;
    }

}

@media (max-width: 50rem) {
    .grid .value{
        max-width: 19ch;
    }
}

@media (max-width: 40rem) {

    .grid {
        display: block;

        * {
            display: block;
        }

        .label {
            font-size: 0.75rem;
            font-weight: 600;
            text-transform: uppercase;
        }

        .value {
            font-size: 1rem;
        }

        .label {
            margin-block-start: 1rem;
        }
    }
}

</style>