<template>
    <BaseModal ref="base_modal" :hideable="true" @close="close">
        <label class="modal-heading" v-html="resource[lang].heading" />
        
        <MatrixInfoCard :matrix="use_sequence ? sequence.matrix : matrix" />
        <div class="display-field">
            <label class="comment-label" v-html="input_field_name" />
            <p class="comment-text" v-html="use_sequence ? sequence[input_field_name] : matrix[input_field_name]" />
        </div>

        <label class="modal-heading-2" v-html="resource[lang].comments" />

        <Comment 
            class="comment"
            v-for="com in comments" 
            :key="com.id" 
            :comment="com" 
            @resolveComment="resolveComment" 
            @unresolveComment="unresolveComment" 
            @deleteComment="deleteComment" 
            @updateComment="updateComment" 
        />

        <div v-if="comments.length === 0" class="not-found">
            <p class="text" v-html="resource[lang].no_comments" />
        </div>

        <label v-if="create" class="modal-heading-2" v-html="resource[lang].write_a_comment" />
        <InputTextArea v-if="create" :label="resource[lang].comment_noun" v-model="comment" />

        <div class="actions flex" v-if="create">
            <Button color="accent" :label="resource[lang].comment_verb" @click="createComment" />
            <Button color="white" :label="resource[lang].discard" @click="close" />
        </div>

    </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/comment-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import Comment from '@/components/Comment'
import InputTextArea from '@/components/basic/InputTextArea'
import MatrixInfoCard from '@/components/MatrixInfoCard'

import { promiseDeleteRequest, promisePostRequest, promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    props: {
        matrix: {
            type: Object,
        },
        sequence: {
            typy: Object,
        },
        input_field_name: {
            type: String,
            required: true
        },
        use_sequence: {
            type: Boolean,
            required: true
        }
    },
    components: {
        BaseModal,
        Button,
        Comment,
        InputTextArea,
        MatrixInfoCard,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            // flag that states if comment was commited
            reload: false,

            // display of comments
            comments: [],

            // input of text field
            comment: '',

            // user abilities
            create: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    mounted() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.loadComments();
            this.getUserAbilities();
        },
        async getUserAbilities() {
            if (this.use_sequence) {
                this.create = await getAbility('create-matrix-comment', this.user, this.sequence.matrix)
            } else {
                this.create = await getAbility('create-matrix-comment', this.user, this.matrix)
            }
        },

        // Requests
        createCommentRequest(request_body) {
            if (this.use_sequence) {
                return promisePostRequest('class-sequence-comment', request_body)
            } else {
                return promisePostRequest('matrix-comment', request_body)
            }
        },
        updateCommentRequest(id, request_body) {
            if (this.use_sequence) {
                return promisePutRequest(`class-sequence-comment/${id}`, request_body)
            } else {
                return promisePutRequest(`matrix-comment/${id}`, request_body)
            }
        },
        deleteCommentRequest(id) {
            if (this.use_sequence) {
                return promiseDeleteRequest(`class-sequence-comment/${id}`)
            } else {
                return promiseDeleteRequest(`matrix-comment/${id}`)
            }
        },
        resolveCommentRequest(id) {
            if (this.use_sequence) {
                return promisePutRequest(`resolve-class-sequence-comment/${id}`)
            } else {
                return promisePutRequest(`resolve-matrix-comment/${id}`)
            }
        },
        unresolveCommentRequest(id) {
            if (this.use_sequence) {
                return promisePutRequest(`unresolve-class-sequence-comment/${id}`)
            } else {
                return promisePutRequest(`unresolve-matrix-comment/${id}`)
            }
        },

        // Actions
        async createComment() {
            const request_body = {
                comment: this.comment,
                input_field_name: this.input_field_name,
            }
            if (this.use_sequence) {
                request_body.class_sequence_id = this.sequence.id;
            } else {
                request_body.matrix_id = this.matrix.id
            }

            this.$store.dispatch('loading', true)
            const response = await this.createCommentRequest(request_body)
            this.$store.dispatch('loading', false)

            this.comments.push(response.data.comment)

            this.comment = '';
            this.reload = true;
        },
        async resolveComment(id) {
            const index = this.comments.findIndex(comment => comment.id == id);

            this.comments[index].resolved = true;

            this.$store.dispatch('loading', true)
            await this.resolveCommentRequest(id);
            this.$store.dispatch('loading', false)
            this.reload = true;
        },
        async unresolveComment(id) {
            const index = this.comments.findIndex(comment => comment.id == id);

            this.comments[index].resolved = false;

            this.$store.dispatch('loading', true)
            await this.unresolveCommentRequest(id);
            this.$store.dispatch('loading', false)
            this.reload = true;
        },
        async updateComment(id, comment) {
            const index = this.comments.findIndex(comment => comment.id == id);

            this.comments[index].comment = comment;

            this.$store.dispatch('loading', true)
            await this.updateCommentRequest(id, this.comments[index]);
            this.$store.dispatch('loading', false)

            this.reload = true;
        },
        async deleteComment(id) {
            const index = this.comments.findIndex(comment => comment.id == id);

            this.comments.splice(index, 1);

            this.$store.dispatch('loading', true)
            await this.deleteCommentRequest(id);
            this.$store.dispatch('loading', false)

            this.reload = true;
        },

        // Functions
        loadComments() {
            let temp_comments = []; 
            if (this.use_sequence) {
                temp_comments = this.sequence.comments.filter(comment => comment.input_field_name == this.input_field_name);
            } else {
                temp_comments = this.matrix.comments.filter(comment => comment.input_field_name == this.input_field_name);
            }
            this.comments = temp_comments;
        },

        // Modal Overhead
        close() {
            this.$refs.base_modal.closeModal();
            this.$emit('close', this.reload);
        },
    },
    watch: {
        matrix: function() {
            this.init();
        },
        sequence: function() {
            this.init();
        },
        input_field_name: function() {
            this.init();
        },
        use_sequence: function() {
            this.init();
        },
        user: function() {
            this.init();
        },
    },
    emits: ['close']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 1rem;
    justify-content: space-between;
}

.actions > * + * {
    margin-inline-start: 1rem;
}

.comment {
    margin-block-start: 0.5rem;
}

.display-field {
    margin-block-start: 1rem;
    background-color: var(--whit);
    padding: 1rem;

    label {
        display: block;
        margin-block-end: 2rem;
    }
} 

@media (max-width: 40rem) {
    .actions > * {
        width: 100% !important;
    }

    .actions > * + * {
        margin: 0;
        margin-block-start: 1rem;
    }
}

</style>